import React from "react";
import { Link } from "react-router-dom";
import HD from "./Hederdisp";
import Navmobile from "../Navmobile";
import Htop from "./Htop";
function Hmain2() {
  return (
    <div className="">
      {" "}
      <header className="header__section">
        <div className="h-[25px]  header__bottom">
          <div className="container-fluid">
            <div className="header__bottom--inner position__relative d-lg-flex justify-content-between align-items-center">
              <div className="header__menu">
                <nav className="header__menu--navigation sticky1">
                  <div className="navmobile">
                    <Navmobile />
                    <Htop />
                  </div>
                  <ul className="header__menu--navigation__ul bg-gray-50 shadow-2xl px-6 mt-3">
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/category">
                        Categories
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/Shop">
                        Shop
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/Women">
                        Women
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/Men">
                        Men
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/kid">
                        Kids
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/couple">
                        Couples
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                    <li className="dropdown101 header__menu--items mega__menu--items sm:mb-2 sm:pb-2">
                      <Link className="header__menu--link" to="/new">
                        New In
                      </Link>
                      <ul className="">
                        <HD />
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Hmain2;
