import React, { useContext, useEffect, useRef, useState } from "react";
import Minicart from "../Minicart";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { DataContext } from "../../DataContext/DataContext";
import { useDispatch, useSelector } from "react-redux";
import { getWishlistTotal } from "../../redux/actions/wishListActon";
import Userdropdown from "./Userdropdown";
import Userdropdown2 from "./Userdropdown2";
import navimg from "../../asset/img/logo/nav-log.png"
function Hmain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedCategory, setSelectedCategory, filteredProducts } =
    useContext(DataContext);
  const totalItems = useSelector((state) => state.wishlist.totalItems);

  const [catag, setcatagory] = useState([]);
  const [query, setQuery] = useState("");
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userState = useSelector((state) => state?.auth);
  const wishlistState = useSelector((state) => state?.auth?.wishlist?.wishlist);

  // Toggle dropdown on hover
  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    // console.log("change the handler");
    navigate("/filter"); // Ensure you have a route for Shop in your router configuration
  };
  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    navigate(`/shop?query=${newQuery}`);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    dispatch(getWishlistTotal());
  }, [dispatch]);

  return (
    <div className="Appp">
      {" "}
      <header className="header__section">
        {" "}
        <div className="main__header header__sticky">
          <div className="container-fluid">
            <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
              <div className="offcanvas__header--menu__open ">
                <Link
                  className="offcanvas__header--menu__open--btn"
                  to="/category"
                  data-offcanvas
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon offcanvas__header--menu__open--svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                      strokeWidth={32}
                      d="M80 160h352M80 256h352M80 352h352"
                    />
                  </svg>
                  <span className="visually-hidden">Menu Open</span>
                </Link>
              </div>
              <div className="main__logo">
                <h1 className="main__logo--title">
                  <Link className="main__logo--link" to="/">
                    <img
                      className="main__logo--img"
                      src={navimg}
                      alt="logo-img"
                    />
                  </Link>
                </h1>
              </div>
              <div className="header__search--widget header__sticky--none d-lg-block">
                <form
                  className="d-flex header__search--form sm:w-72 rounded-2xl"
                  action=""
                >
                  

                  <div className="header__search--box">
                    <label>
                      <input
                        className="header__search--input"
                        placeholder="Keyword here..."
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                      />
                    </label>
                    <button
                      className="header__search--button bg__secondary text-white"
                      type="submit"
                      aria-label="search button"
                    >
                      <svg
                        className="header__search--button__svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.51"
                        height="26.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit={10}
                          strokeWidth={32}
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit={10}
                          strokeWidth={32}
                          d="M338.29 338.29L448 448"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div className="header__account header__sticky--none">
                <ul className="d-flex">
                  <li className="header__account--items">
                    <div
                      className="relative"
                      ref={dropdownRef}
                      onMouseEnter={handleMouseEnter} // Open dropdown on hover
                      onMouseLeave={handleMouseLeave} // Close dropdown when leaving the icon
                    >
                      {/* Person Icon */}
                      <button className="header__account--btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26.51"
                          height="23.443"
                          viewBox="0 0 512 512"
                          className="svg ml-[23px]"
                        >
                          <path
                            d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={32}
                          />
                          <path
                            d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                            fill="none"
                            stroke="currentColor"
                            strokeMiterlimit={10}
                            strokeWidth={32}
                          />
                        </svg>
                        <span className="header__account--btn__text">
                          My Account
                        </span>
                      </button>

                      {/* Dropdown menu */}
                      {isDropdownOpen && (
                        <div className="absolute right-0 mt-4 shadow-lg rounded-lg overflow-hidden z-40">
                          {userState?.user ? (
                            <Userdropdown /> // Show authenticated dropdown
                          ) : (
                            <Userdropdown2 /> // Show unauthenticated dropdown
                          )}
                        </div>
                      )}
                    </div>
                  </li>

                  <li className="header__account--items d-none d-lg-block">
                    <Link className="header__account--btn" to="wishlist">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.51"
                        height="23.443"
                        viewBox="0 0 512 512"
                        className="svg ml-[16px]"
                      >
                        <path
                          d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={32}
                        />
                      </svg>
                      <span className="header__account--btn__text">
                        {" "}
                        Wish List
                      </span>
                      <span className="items__count wishlist">
                        {totalItems}
                      </span>
                    </Link>
                  </li>
                  <li className="header__account--items sm:-ml-3">
                    <Minicart />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Hmain;
