import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllBlogs } from "../features/ads/adsSlice";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import './popads.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

function HeroAds() {
    const dispatch = useDispatch();
    const { blog, isLoading, isError, isSuccess, message } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(getAllBlogs());
    }, [dispatch]);

    // Filter blogs based on desired categories
    const filteredBlogs = React.useMemo(() => {
        const desiredCategories = ['men', 'couple', 'women', 'kid', 'new'];
        return blog.filter(item => desiredCategories.includes(item.category));
    }, [blog]);

    if (isLoading) {
        return (
            <div className='flex space-x-2 justify-center items-center h-auto dark:invert mt-5'>
                <span className='sr-only'>Loading...</span>
                <div className='h-8 w-8 bg-red-600 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                <div className='h-8 w-8 bg-red-600 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div className='h-8 w-8 bg-red-600 rounded-full animate-bounce'></div>
            </div>
        );
    }

    if (isError) {
        return <div>Error: {message}</div>;
    }

    if (filteredBlogs.length === 0) {
        return (
            <div className="bg-gray-300 shadow-lg text-black rounded-md p-4 mt-8 ml-5 mr-5">
                No Ads available.
            </div>
        );
    }
    const isOffline = !navigator.onLine;
    if (isOffline) {
        return (
            <div className="hidden items-center justify-center">
                <div className="text-center">
                    <h2 className="hidden text-2xl font-bold mb-2">Oops!</h2>
                    <p className="hidden">You are currently offline. Please check your internet connection.</p>
                </div>
            </div>
        );
    }
    return (
        <div className="-mt-10 xl:mt-20 lg:mt-20 max-w-screen-lg">
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation={{ hidden: true }}
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                loop={true}
            >
                {filteredBlogs.map((item, index) => (
                    item.images.map((image, imgIndex) => (
                        <SwiperSlide key={`${index}-${imgIndex}`} className="flex justify-center items-center">
                            <Link to={`/${item.category}`} className="flex justify-center items-center w-full h-full sm:pb-10 ">
                                <img
                                    src={image.secure_url}
                                    alt={`slide ${index + 1}-${imgIndex + 1}`}
                                    className="sm:h-60 md:h-[350px] lg:h-[360px] xl:h-[380px] w-screen  object-contain"
                                />
                            </Link>
                        </SwiperSlide>
                    ))
                ))}
            </Swiper>
        </div>
    );
}

export default HeroAds;
