import React from "react";
import payment from "../asset/img/other/payment-visa-card.png"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>

      
        {/* Remove the container if you want to extend the Footer to full width. */}
        <div className=" bg-zinc-900 sm:hidden">
          {/* Footer */}
          <footer
            className="text-center text-lg-start text-gray-50 mx-5"

          >
            {/* Section: Social media */}
            <section
              className="d-flex justify-content-between p-4 text-white"

            >
              {/* Left */}
              
              {/* Left */}
              {/* Right */}
              
              {/* Right */}
            </section>
            {/* Section: Social media */}
            {/* Section: Links  */}
            <section className="">
              <div className="container text-center text-md-start mt-0">
                {/* Grid row */}
                <div className="row mt-3">
                  {/* Grid column */}
                  <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    {/* Content */}
                    <h6 className="text-uppercase fw-bold">Wib Fashion</h6>
                    <hr
                      className="mb-4 mt-0 d-inline-block mx-auto"
                      style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                    />
                    <p className="footer__widget--menu__text">
                      We are passionate about preserving and promoting the rich tapestry of
                      global cultures through fashion. Our mission is to connect people with
                      authentic, handcrafted designs that tell stories.
                    </p>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    {/* Links */}
                    <h6 className="text-uppercase fw-bold">Products</h6>
                    <hr
                      className="mb-4 mt-0 d-inline-block mx-auto"
                      style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                    />
                    <p>
                      <a href="shop" className="footer__widget--menu__text">
                        Shopping
                      </a>
                    </p>
                    <p>
                      <a href="cart" className="footer__widget--menu__text">
                        Cart
                      </a>
                    </p>
                    <p>
                      <a href="about" className="footer__widget--menu__text">
                        About Us
                      </a>
                    </p>
                    <p>
                      <a href="contact" className="footer__widget--menu__text">
                        Contact Us
                      </a>
                    </p>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    {/* Links */}
                    <h6 className="text-uppercase fw-bold">Useful links</h6>
                    <hr
                      className="mb-4 mt-0 d-inline-block mx-auto"
                      style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                    />
                    <p>
                      <a href="myaccount" className="footer__widget--menu__text">
                        Your Account
                      </a>
                    </p>
                    <p>
                      <a href="wishlist" className="footer__widget--menu__text">
                        Wishlist
                      </a>
                    </p>
                    <p>
                      <a href="privacyandpolicy" className="footer__widget--menu__text">
                        Privacy Policy
                      </a>
                    </p>
                    <p>
                      <a href="login" className="footer__widget--menu__text">
                        Login
                      </a>
                    </p>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    {/* Links */}
                    <h6 className="text-uppercase fw-bold">Contact</h6>
                    <hr
                      className="mb-4 mt-0 d-inline-block mx-auto"
                    />
                    <p className="flex mt-1 footer__widget--menu__text">
                      <ImLocation size={30} className="text-teal-500 mr-3" /> Bole, Addis Abeba, Ethiopia
                    </p>
                    <p className="flex mt-1 footer__widget--menu__text">
                      <MdOutlineMarkEmailUnread size={30} className="text-teal-500 mr-3" /> wibfashions@gmail.com
                    </p>
                    <p className="flex mt-3 footer__widget--menu__text">
                    <FaPhoneVolume size={30} className="text-teal-500 mr-3" /> +251924888811
                    </p>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
            </section>
            {/* Section: Links  */}
            {/* Copyright */}
            <div className="footer__bottom d-flex justify-content-between align-items-center">
              <p className="copyright__content m-0 footer__widget--menu__text">
                Copyright &copy; {currentYear}{" "}
                <a className="copyright__content--link" href="https://www.wibfashion.com/">
                  Wib Fashions
                </a>{" "}
                . All Rights Reserved.
              </p>
              <div className="footer__payment text-right">
                <img
                  className="display-block"
                  src={payment}
                  alt="visa-card"
                />
              </div>
            </div>
            <div className="text-center text-gray-600 mt-2 space-y-3">
              <div className="flex justify-center items-center space-x-2">
                <img
                  src="/logo1.png"
                  alt="Rise Tech Logo"
                  className="h-12 w-12 object-contain"
                />
                <span className="text-lg font-bold">
                  Powered by <span className="text-orange-500">Rise Tech</span>
                </span>
              </div>
              <a
                href="https://www.risetechet.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange-500 font-semibold hover:underline"
              >
                Visit Our Website
              </a>
            </div>
            {/* Copyright */}
          </footer>
          {/* Footer */}
        </div>

    </>
  );
}

export default Footer;
