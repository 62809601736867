import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../DataContext/DataContext";
import { base_url } from "../../utils/axiosConfig";

function Hederdisp() {
  const [subcatagory, setsubcatagory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (!navigator.onLine) {
        console.error('You are offline. Please check your internet connection.');
        return;
      }
      try {
        const response = await axios.get(`${base_url}subcategory`);

        // Using Map to filter out duplicates by title
        const uniqueSubcategoriesMap = new Map(
          response.data.map(item => [item.title, item])
        );

        // Converting back the Map to an array
        const uniqueSubcategories = [
          { title: "All", href: "#" },
          ...Array.from(uniqueSubcategoriesMap.values())
        ];

        setsubcatagory(uniqueSubcategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load subcategories.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const { selectedCategory, setSelectedCategory, filteredProducts, data } =
    useContext(DataContext);

  const handleFilter = (title) => {
    setSelectedCategory(title === "All" ? "" : title);
  };

  const SubcategoryList = ({ subcatagory, loading, error, handleFilter }) => (
    <li className="header__mega--menu__li -ml-10 p-4 w-[250px]">
      {/* Title */}
      <span className="header__mega--subtitle text-lg font-semibold text-gray-800 mb-2 block">
        Sub Categories
      </span>

      {/* Loading, Error, or Subcategory List */}
      {loading ? (
        <p className="text-gray-500 text-center">Loading subcategories...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : subcatagory.length === 0 ? (
        <p className="text-gray-500 text-center">No subcategories available</p>
      ) : (
        <ul className="scrollableDiv header__mega--sub__menu max-h-[400px] overflow-y-auto">
          {subcatagory.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className="w-full flex items-center justify-between px-4 py-2 rounded-md transition-all duration-200 hover:bg-red-100 hover:text-red-900"
            >
              {/* Subcategory Title */}
              <p
                className="header__mega--sub__menu--title text-gray-700 font-medium hover:text-red-900"
                onClick={() => handleFilter(item.title)}
              >
                {item.title}
              </p>

              {/* Forward Icon */}
              <ion-icon name="chevron-forward-outline" className="text-gray-500 text-lg"></ion-icon>
            </Link>
          ))}
        </ul>
      )}
    </li>

  );

  const chunks = (array, size = 3) => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  const popularProducts = data?.filter((product) =>
    product?.tags?.includes("recommended")
  ) || [];
  const popularVipProducts = data?.filter((product) =>
    product?.tags?.includes("vip")
  ) || [];

  // Check if there are any filtered products for the selected category
  const hasFilteredProducts = filteredProducts.length > 0;

  // Check if filtered products have images
  const hasImages = filteredProducts.some(
    (product) => product?.colors?.[0]?.images?.length > 0
  );
  const handleCategoryClick = (subcategory) => {
    navigate(`/shop?subcategory=${subcategory}`);
    //window.location.reload();
  };
  return (
    <div>
      <ul className="header__mega--menu flex flex-wrap">
        {/* Reusable Subcategory List */}
        <SubcategoryList
          subcatagory={subcatagory}
          loading={loading}
          error={error}
          handleFilter={handleFilter}
        />

        {/* Conditionally render Filtered products or a message */}
        {hasFilteredProducts ? (
          hasImages ? (
            <li className="li1 header__mega--menu__li">
              <span className="header__mega--subtitle">Wib Fashions</span>
              <ul className="header__mega--sub__menu scrollableMenu -ml-20">
                <div className="header__mega--sub__menu scrollableMenu">
                  {chunks(filteredProducts).map((chunk, chunkIndex) => (
                    <div key={chunkIndex} className="flex space-x-2 mb-4">
                      {chunk.map((item, index) => (
                        <div
                          key={index}
                          className="header__mega--sub__menu_li flex flex-col items-center justify-center"
                          onClick={() => handleCategoryClick(item.subcategory)}
                        >
                          <img
                            src={item?.colors?.[0]?.images?.[0]?.secure_url || ""}
                            alt={item.title}
                            className="w-24 h-24 rounded-full"
                          />
                          <Link className="header__mega--sub__menu--title">
                            {item.subcategory}
                          </Link>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </ul>
            </li>
          ) : (
            <li className="li1 header__mega--menu__li">
              <span className="header__mega--subtitle">WIB</span>
              <p className="bg-gray-200 m-auto shadow-lg p-10">No products available for the selected Products.</p>
            </li>
          )
        ) : (
          <li className="li1 header__mega--menu__li">
            <span className="header__mega--subtitle">WIB</span>
            <p className="bg-gray-200 m-auto shadow-lg p-10">No products available for the selected Products.</p>
          </li>
        )}

        {/* Recommended Products Section */}
        <li className="w-[350px] px-4">
          <span className="header__mega--subtitle font-semibold text-lg block mb-2">
            Recommended
          </span>
          <div className="max-h-[400px] overflow-y-auto bg-white shadow-md rounded-lg p-4">
            <div className="grid grid-cols-3 gap-4">
              {popularProducts.map((product) => (
                <div
                  key={product._id}
                  className="flex flex-col items-center cursor-pointer transition hover:scale-105"
                  onClick={() => handleCategoryClick(product.subcategory)}
                >
                  <img
                    src={product?.colors?.[0]?.images?.[0]?.secure_url || ""}
                    className="w-24 h-24 rounded-full object-cover"
                    alt={product.title || "Product"}
                  />
                  <Link
                    className="text-sm font-medium text-gray-700 hover:text-indigo-600 mt-2 text-center"
                    to="/shop"
                  >
                    {product.subcategory}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </li>
        {/* VIP Products Section */}
        <li className="w-[320px] px-4">
          <span className="header__mega--subtitle font-semibold text-lg block mb-2">
            VIP
          </span>
          <div className="max-h-[500px] overflow-y-auto bg-white shadow-md rounded-lg p-4">
            <div className="grid grid-cols-3 gap-4">
              {popularVipProducts.map((product) => (
                <div
                  key={product._id}
                  className="flex flex-col items-center cursor-pointer transition hover:scale-105"
                  onClick={() => handleCategoryClick(product.subcategory)}
                >
                  <img
                    src={product?.colors?.[0]?.images?.[0]?.secure_url || ""}
                    className="w-24 h-24 rounded-full object-cover"
                    alt={product.title || "Product"}
                  />
                  <Link
                    className="text-sm font-medium text-gray-700 hover:text-indigo-600 mt-2 text-center"
                    to="/shop"
                  >
                    {product.subcategory}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Hederdisp;
