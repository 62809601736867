import React from "react";
import { Link } from "react-router-dom";

function Htopbar() {
  return (
    <div className="App">
      <header className="header__section">
        {" "}
        <div className="header__topbar bg__secondary">
          <div className="container-fluid">
            <div className="header__topbar--inner d-flex align-items-center justify-content-between">
              <div className="header__shipping">
                <ul className="header__shipping--wrapper d-flex">
                  <li className="header__shipping--text text-white">
                    <h2 className="protest-guerrilla-regular">
                     👗 WELCOME TO WIB FASION
                    </h2>{" "}
                  </li>
                  <li className="header__shipping--text text-white font-bold d-sm-2-none">
                    <h4> 🚚 TRACK YOUR ORDERS</h4>
                  </li>

                  <li className="header__shipping--text text-white d-sm-2-none">
                    {" "}
                    <Link className="header__shipping--text__link" href>
                      <span>🎉 SALES 15% DISCOUNT</span>{" "}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="language__currency d-none d-lg-block">
                <ul className="d-flex align-items-center font-bold text-lg">
                  <li className="language__currency--list">
                    <Link
                      className="hover:text-white header__mega--sub__menu--title"
                      to="/myaccount"
                    >
                     🔑 My Acount
                    </Link>
                  </li>
                  <li className="language__currency--list">
                    <Link
                      className="hover:text-white header__mega--sub__menu--title"
                      to="/login"
                    >
                      📝 Register
                    </Link>
                  </li>
                  <li className="language__currency--list">
                    <Link
                      className="hover:text-white header__mega--sub__menu--title"
                      to="/login"
                    >
                     🔓 login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Htopbar;
